import React from 'react'

import Layout from '@components/layout'
import ListPage from '@components/post/list-page'

import PageSelector from '@components/post/page-selector'
import CardList from '@components/post/card-list'

import parameters from '@parameters'

export default ({
  pageContext: {
    additionalContext: { breadcrumbs, keywords, title },
    group,
    index,
    pageCount,
    pathPrefix,
  },
  location,
}) => {
  const selector = (
    <PageSelector
      breadcrumbs={breadcrumbs}
      index={index}
      pageCount={pageCount}
      pathPrefix={pathPrefix}
      width={parameters.paginatorWidth}
    />
  )

  const list = <CardList items={group} />

  return (
    <Layout title={title} keywords={keywords} pathname={location.pathname}>
      <ListPage selector={selector} list={list} title={title} />
    </Layout>
  )
}
